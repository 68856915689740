<template>
    <div>
        <v-card class="indicator-card mb-3" flat v-for="(indicator, key) in value" :key="key">
            <v-btn class="ml-auto d-block" v-if="key > 0" fab small elevation="0" color="error" @click="removeindicator(key)">
                <v-icon dark>
                    mdi-trash-can
                </v-icon>
            </v-btn>
            <label class="font-weight-regular">Indicator {{ key + 1 }}</label>
            <v-text-field solo class="theme-input mb-5" background-color="#ECF4EF" type="text" v-model="value[key].title"
                :readonly="!edit" hide-details></v-text-field>
            <label class="font-weight-regular">Die meet ik als volgt</label>
            <v-text-field solo class="theme-input mb-5" background-color="#ECF4EF" type="text"
                v-model="value[key].measurement" :readonly="!edit" hide-details></v-text-field>
            <label class="font-weight-regular">De informant die informatie verstrekt over de verandering is</label>
            <v-text-field solo class="theme-input mb-5" background-color="#ECF4EF" type="text"
                v-model="value[key].informant" :readonly="!edit" hide-details></v-text-field>
        </v-card>
        <v-btn class="mx-auto d-block" fab small elevation="0" color="primary" @click="addindicator()">
            <v-icon dark>
                mdi-plus
            </v-icon>
        </v-btn>
        <p class="text-center mt-1">Indicator toevoegen</p>
    </div>
</template>
<script>
// import api from "../services/api";
// import config from '../config'

export default {
    data: () => ({
    }),
    props: {
        edit: Boolean,
        value: Array
    },
    methods: {
        addindicator() {
            this.value.push(
                {
                    title: "",
                    measurement: "",
                    informant: ""
                }
            )
        },
        removeindicator(index) {
          this.value.splice(index, 1);
        }
    }
}
</script>
<style>
.indicator-card {
    background-color: #fff;
    border: #9FC9AF 1px solid !important;
    padding: 20px 10px 10px 10px;

}
</style>