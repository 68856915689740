<template>
    <v-navigation-drawer v-model="sidebar" light floating clipped app width="350" height="100%"
        color="rgba(255,255,255,0.96)" class="mt-1 mr-1 pa-10 rounded-lg rounded-l-0">
        <v-list flat light>
            <v-list-item-group light>
                <v-list-item color="themepurple" :to="'/project/' + $route.params.id + '/transformatie-canvas'">
                    Transformatiecanvas
                </v-list-item>
            </v-list-item-group>
            <v-list-item color="themepurple" :to="'/project/' + $route.params.id + '/monitoringsplan'">
                Monitoringsplan
            </v-list-item>
            <v-list-item color="themepurple" :to="'/project/' + $route.params.id + '/tellen-en-vertellen'">
               Tellen en vertellen
            </v-list-item>
   
            <v-spacer></v-spacer>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
//import config from '../config'
export default {
    components: {},

    props: {
        sidebar: Boolean,
    },
    computed: {
        ...mapGetters({ user: 'getuser' }),
    },
    methods: {

    },
    data: () => ({


    }),
};
</script>

<style></style>
