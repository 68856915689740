<template>
    <v-main>
        test
    </v-main>
</template>

<script>
export default {
    data() {}
};
</script>