<template>
	<div>
		<v-navigation-drawer v-model="sidebar" light floating clipped app width="350" height="100%" color="rgba(255,255,255,0.96)" class="mt-1 mr-1 pa-10 rounded-lg rounded-l-0">
			<v-list flat light>
				<v-list-item-group v-model="tab" light>
					<v-list-item color="themepurple" v-for="(item, i) in menuitems" :key="i" :disabled="i == tab">
						<v-list-item-title :key="i"> {{ i + 1 }}. {{ item.title }} </v-list-item-title>
					</v-list-item>
				</v-list-item-group>
				<v-spacer></v-spacer>
			</v-list>
		</v-navigation-drawer>
		<v-app-bar-nav-icon class="left-top-absolute ma-4" @click.stop="sidebar = !sidebar"></v-app-bar-nav-icon>
		<v-main class="pa-0 ml-1 px-15 pb-15">
			<div v-if="tab === 0">
				<h1 class="mb-5">Waarom</h1>
				<v-row>
					<v-col cols="12" md="6">
						<label for="">Probleem</label>
						<v-textarea background-color="#ECF4EF" class="theme-input" solo title="Probleem" placeholder="Let goed op de toelichting." v-model="data.why.problem" :readonly="!edit"></v-textarea>

						<label for="">Gewenste verandering</label>
						<v-textarea background-color="#ECF4EF" class="theme-input" solo title="Gewenste verandering" placeholder="Let goed op de toelichting." v-model="data.why.desired" :readonly="!edit"></v-textarea>
					</v-col>
					<v-col cols="12" md="1"></v-col>
					<v-col cols="12" md="5">
						<h5 class="mb-2">Toelichting</h5>
						<v-card class="rounded-xl pa-4" elevation="0" outlined>
							<v-card-text>
								<h6>Probleem</h6>
								<ul>
									<li>Wat is het probleem?</li>
									<li>
										Waarom is dit een probleem?
										<small>(Stel deze vraag minimaal 5x!)</small>
									</li>
									<li>Wat gebeurt er als dit probleem niet wordt aangepakt?</li>
									<li>Wie heeft het meest last van de huidige situatie?</li>
								</ul>
								<br />
								<h6>Gewenste verandering</h6>
								<ul>
									<li>Wat is het probleem?</li>
									<li>Wat is de gewenste verandering?</li>
									<li>Wie merkt er het meest van de verandering?</li>
									<li>Wat levert het op voor de betrokkenen?</li>
								</ul>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
				<v-footer class="ml-2 pa-10" color="transparent" fixed>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined @click="submit('concept')" v-if="edit" color="themepurple"><v-icon>fas fa-save</v-icon></v-btn>
							<v-btn class="mr-3 btn" outlined to="/" color="themepurple">Terug</v-btn>
							<v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
						</v-col>
					</v-row>
				</v-footer>
			</div>

			<div v-if="tab === 1">
				<h1 class="mb-5">Doel</h1>
				<v-row>
					<v-col cols="12" md="6">
						<label for="">Doelstelling</label>
						<v-textarea background-color="#ECF4EF" class="theme-input" solo title="Doelstelling" v-model="data.objective.objective" placeholder="Let goed op de toelichting." :readonly="!edit"></v-textarea>
					</v-col>
					<v-col cols="12" md="1"></v-col>
					<v-col cols="12" md="5">
						<h5 class="mb-2">Toelichting</h5>
						<v-card class="rounded-xl pa-4" elevation="0" outlined>
							<v-card-text>
								<p>De doelstelling is gebaseerd op de zingeving. Het resultaat levert een bijdrage aan de hogere ambitie. Wat is de hogere ambitie waar je aan bijdraagt?</p>
								<ul>
									<li>
										Wat wordt 'beter, mooier, goedkoper, sneller, effectiever'
										<b>-er + werkwoord.</b>
									</li>
									<li>Vaak termen als 'Bijdragen aan', 'Bevorderen van', 'Zorgen voor'.</li>
									<li>De doelstelling zelf hoeft niet volledig te worden gerealiseerd, in tegenstelling tot het resultaat.</li>
									<li>De doelstelling is de verantwoordelijkheid van de opdrachtgever.</li>

									<li>Wat ga je doen om je gewenste verandering te behalen, zodat je bijdraagt aan de ambitie?</li>
									<li>Wat is de gewenste verandering?</li>
									<li>De doelstelling moet geformuleerd worden op tactisch niveau.</li>
									<li>Zorg dat je invloed hebt op de uitkomst.</li>
								</ul>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
				<v-footer class="ml-2 pa-10" color="transparent" fixed>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined v-if="edit" @click="submit('concept')" color="themepurple"><v-icon>fas fa-save</v-icon></v-btn>
							<v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
							<v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
						</v-col>
					</v-row>
				</v-footer>
			</div>

			<div v-if="tab === 2">
				<h1 class="mb-5">Meetlat</h1>
				<v-row>
					<v-col cols="12" md="6">
						<div v-for="(subject, x) in data.measurement" :key="x">
							<p :class="x > 0 ? 'mt-10 mb-10' : 'mt-0 mb-10'">
								<label for="">{{ subject.label }}</label>
							</p>
							<v-row :key="i" v-for="(item, i) in subject.items">
								<v-col cols="2" class="py-1">
									<v-text-field solo dense width="50px" class="theme-input number-input" background-color="#ECF4EF" type="number" hide-details min="0" max="100" v-model="data.measurement[x].items[i].value" :readonly="!edit"> </v-text-field>
								</v-col>
								<v-col cols="10" class="my-auto py-1">
									<p class="mb-0">
										{{ item.label }}
									</p>
								</v-col>
							</v-row>
						</div>
					</v-col>
					<v-col cols="12" md="1"></v-col>
					<v-col cols="12" md="5">
						<h5 class="mb-2">Toelichting</h5>
						<v-card class="rounded-xl pa-4" elevation="0" outlined>
							<v-card-text>
								<p>De jeugdwet is vertaald in de transformatiemeetlat. Daarom zijn er 9 uitgangspunten in de meetlat te vinden. Door te bepalen aan welke uitgangspunten je werkt, ontstaat een beeld van de bijdrage aan de transformatie jeugdhulp.</p>
								<h6>Verdeel 100 punten over de uitgangspunten van de meetlat.</h6>
								<p>De meeste punten schrijf je toe aan het belangrijkste uitgangspunt. De uitgangspunten die het hoogste scoren, komen terug bij het formuleren van je doelen.</p>
							</v-card-text>
						</v-card>
						<p class="mt-10">{{ measurementCompleteText }}</p>
					</v-col>
				</v-row>
				<v-footer class="ml-2 pa-10" color="transparent" fixed>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined v-if="edit" @click="submit('concept')" color="themepurple"><v-icon>fas fa-save</v-icon></v-btn>
							<v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
							<v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
						</v-col>
					</v-row>
				</v-footer>
			</div>

			<div v-if="tab === 3">
				<h1 class="mb-5">Hoe</h1>
				<v-row>
					<v-col cols="12" md="6">
						<label for="">Stappenplan</label>
						<v-textarea background-color="#ECF4EF" class="theme-input" solo title="Stappenplan" v-model="data.step_by_step_plan.plan" :readonly="!edit" placeholder="Let goed op de toelichting."></v-textarea>
					</v-col>
					<v-col cols="12" md="1"></v-col>
					<v-col cols="12" md="5">
						<h5 class="mb-2">Toelichting</h5>
						<v-card class="rounded-xl pa-4" elevation="0" outlined>
							<v-card-text>
								<p>Dit onderdeel is gericht op het opstellen van een stappenplan. Hier is ook ruimte voor het formuleren van randvoorwaarden. Randvoorwaarden worden nog wel eens verward met resultaten.</p>
								<p>Door de voorwaarden hier te noteren, is het bij de volgende stap makkelijker om de resultaten te formuleren.</p>
								<ul>
									<li>Knip de route op aan de hand van mijlpalen.</li>
									<li>Wat moet er gedaan worden om het van begin tot het eind uit te voeren? Welke deelactiviteiten worden er onderscheiden?</li>
									<li>Formuleer de randvoorwaarden. Is je doel 'snel hulp kunnen bieden'?</li>
								</ul>
								<p>Dan is een randvoorwaarde dat de aanbieder die je inhuurt binnen 12 uur beschikbaar is. Omdat het een voorwaardelijkheid is, kan het geen resultaat zijn.</p>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
				<v-footer class="ml-2 pa-10" color="transparent" fixed>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined v-if="edit" @click="submit('concept')" color="themepurple"><v-icon>fas fa-save</v-icon></v-btn>
							<v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
							<v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
						</v-col>
					</v-row>
				</v-footer>
			</div>

			<div v-if="tab === 4">
				<h1 class="mb-5">Wat</h1>
				<v-row>
					<v-col cols="12" md="6">
						<label for="">Resultaten</label>
						<v-textarea background-color="#ECF4EF" class="theme-input" solo title="Resultaten" :readonly="!edit" v-model="data.results.result" placeholder="Let goed op de toelichting."></v-textarea>
					</v-col>
					<v-col cols="12" md="1"></v-col>
					<v-col cols="12" md="5">
						<h5 class="mb-2">Toelichting</h5>
						<v-card class="rounded-xl pa-4" elevation="0" outlined>
							<v-card-text>
								<p>De optelsom van de resultaten moet leiden tot het behalen van de doelstelling.</p>

								<ul>
									<li>Neem 3 tot 5 resultaten als uitgangspunt.</li>
									<li>Begin bij de key results. Dit zijn de onmisbare resultaten. Behaal je deze resultaten niet, dan behaal je de doelstelling niet.</li>
									<li>Overige resultaten zijn vaak randvoorwaardelijk. Wil je ze opschrijven, noteer ze dan bij de randvoorwaarden.</li>
									<li>Randvoorwaarden noteer je bij 4. Randvoorwaarden zijn nodig om het resultaat te kunnen behalen.</li>
									<li>Resultaten gaan we meten en op randvoorwaarden gaan we sturen.</li>
								</ul>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
				<v-footer class="ml-2 pa-10" color="transparent" fixed>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined v-if="edit" @click="submit('concept')" color="themepurple"><v-icon>fas fa-save</v-icon></v-btn>
							<v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
							<v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
						</v-col>
					</v-row>
				</v-footer>
			</div>

			<div v-if="tab === 5">
				<h1 class="mb-5">Informaten</h1>
				<v-row>
					<v-col cols="12" md="6">
						<label for="">Wie de verandering gaat merken</label>
						<v-textarea background-color="#ECF4EF" class="theme-input" solo title="Wie de verandering gaat merken" placeholder="Let goed op de toelichting." :readonly="!edit" v-model="data.informatics.impact"></v-textarea>
					</v-col>
					<v-col cols="12" md="1"></v-col>
					<v-col cols="12" md="5">
						<h5 class="mb-2">Toelichting</h5>
						<v-card class="rounded-xl pa-4" elevation="0" outlined>
							<v-card-text>
								<p>Noteer hier de stake holders die informatie kunnen leveren over de gewenste verandering.</p>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
				<v-footer class="ml-2 pa-10" color="transparent" fixed>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined v-if="edit" @click="submit('concept')" color="themepurple"><v-icon>fas fa-save</v-icon></v-btn>
							<v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
							<v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
						</v-col>
					</v-row>
				</v-footer>
			</div>

			<div v-if="tab === 6">
				<h1 class="mb-5">Financieel Perspectief</h1>
				<v-row>
					<v-col cols="12" md="6">
						<label for="">Huidig, dreigend en scenario met bijbehorende interventies op geld gezet</label>
						<v-textarea background-color="#ECF4EF" class="theme-input" solo title="Huidig, dreigend en scenario met bijbehorende interventies op geld gezet" v-model="data.financial_perspective.scenario" :readonly="!edit" placeholder="Let goed op de toelichting."></v-textarea>
					</v-col>
					<v-col cols="12" md="1"></v-col>
					<v-col cols="12" md="5">
						<h5 class="mb-2">Toelichting</h5>
						<v-card class="rounded-xl pa-4" elevation="0" outlined>
							<v-card-text>
								<h6>Beschrijf de volgende scenario's:</h6>

								<ul>
									<li>Het huidige financiële scenario. Wat kost de inzet nu?</li>
									<li>Het dreigende scenario. Wat gaat het kosten als we niet veranderen?</li>
									<li>Het veranderscenario. Wat gaat het gewenste scenario kosten ten opzichte van het huidige scenario?</li>
								</ul>
								<p>Wat is de investering van de verandering? Hoe ziet je begroting eruit en welk budget heb je nodig?</p>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
				<v-footer class="ml-2 pa-10" color="transparent" fixed>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined v-if="edit" color="themepurple" @click="submit('concept')"><v-icon>fas fa-save</v-icon></v-btn>
							<v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
							<v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
						</v-col>
					</v-row>
				</v-footer>
			</div>

			<div v-if="tab === 7">
				<h1 class="mb-5">Voorwaarden</h1>
				<v-row>
					<v-col cols="12" md="6">
						<label for="">Budget, termijn, eisen</label>
						<v-textarea background-color="#ECF4EF" class="theme-input" solo title="Budget, termijn, eisen" :readonly="!edit" v-model="data.terms.terms" placeholder="Let goed op de toelichting."></v-textarea>
					</v-col>
					<v-col cols="12" md="1"></v-col>
					<v-col cols="12" md="5">
						<h5 class="mb-2">Toelichting</h5>
						<v-card class="rounded-xl pa-4" elevation="0" outlined>
							<v-card-text>
								<p>Formuleer hier de voorwaarden en de afbakening die de opdrachtgever geeft.</p>
								<ul>
									<li>Wanneer moet het af zijn?</li>
									<li>Wat is het budget?</li>
									<li>Wie moet er betrokken worden?</li>
									<li>Wat is de afbakening? Tot waar gaat het en wat doe je niet?</li>
								</ul>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
				<v-footer class="ml-2 pa-10" color="transparent" fixed>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined v-if="edit" @click="submit('concept')" color="themepurple"><v-icon>fas fa-save</v-icon></v-btn>
							<v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
							<v-btn class="btn" @click="nextdialog = true" dark color="themepurple">Volgende</v-btn>
						</v-col>
					</v-row>
				</v-footer>
			</div>

			<!-- Download / submit dialog -->
			<v-dialog v-model="nextdialog" content-class="theme-popup">
				<div class="text-right">
					<v-btn @click="nextdialog = false" icon class="" elevation="0" color="themepurple">
						<v-icon>fas fa-times </v-icon>
					</v-btn>
				</div>
				<div v-if="(senddate = true)">
					<div class="text-center pb-5">
						<h3>Weet je zeker dat je wil inleveren?</h3>
					</div>
					<div class="text-center">
						<p>Wanneer je hem inlevert word de project Procesmanager/Clustermanager/Lijnmanager op de hoogste gesteld.</p>
					</div>
					<div class="text-center pt-5">
						<v-btn class="btn text-primary mr-3" color="#fff" @click="download()">Downloaden</v-btn>
						<v-btn class="btn" color="themepurple" dark @click="submit('save')">Inleveren</v-btn>
					</div>
				</div>
				<div v-else>
					<div class="text-center pb-5">
						<h3>Verstuurd</h3>
					</div>
					<div class="text-center">
						<p>Document is verstuurd naar organisator.</p>
					</div>
				</div>
			</v-dialog>
		</v-main>
	</div>
</template>

<script>
// import optiontable from "@/components/optiontable.vue";
import api from "../../../services/api";
import config from '../../../config';
export default {
	components: {},
	data() {
		return {
			submittype: "concept",
			sidebar: true,
			ready: true,
			nextdialog: false,
			senddata: false,
			edit: false,
			menuitems: [
				{
					title: "Waarom",
					slug: "waarom"
				},
				{
					title: "Doel",
					slug: "doel"
				},
				{
					title: "Meetlat",
					slug: "meetlat"
				},
				{
					title: "Hoe",
					slug: "hoe"
				},
				{
					title: "Wat",
					slug: "wat"
				},
				{
					title: "Informanten",
					slug: "informanten"
				},
				{
					title: "Financieeel Perspectief",
					slug: "financieel-perspectief"
				},
				{
					title: "Voorwaarden",
					slug: "voorwaarden"
				},
			],
			tab: 0,
			data: {
				why: {
					problem: "",
					desired: "",
				},
				objective: {
					objective: "",
				},

				measurement: [{
					label: "Perspectief van ouders en jeugdige versterken/ verbeteren",
					items: [
						{
							label: "Het belang van de jeugdige staat voorop",
							value: 0
						},
						{
							label: "Jeugdigen en / of ouders worden actief betrokken",
							value: 0
						},
						{
							label: "Versterking van eigen kracht en verantwoordelijkheid van jeugdige en / of ouders",
							value: 0
						}
					]
				}, {
					label: "Perspectief van de professionals",
					items: [
						{
							label: "Betere samenwerking: 1 gezin, 1 plan, 1 regisseur",
							value: 0
						},
						{
							label: "Minder regeldruk voor professionals",
							value: 0
						}
					]
				},
				{
					label: "Kostenbeheersing",
					items: [
						{
							label: "Meer doelmatige hulp(efficiënt / effectief)",
							value: 0
						},
						{
							label: "Geen onnodige jeugdhulp als ook het netwerk iets kan betekenen (normaliseren en ontzorgen)",
							value: 0
						}
					]
				},
				{
					label: "Verbeteren van kwaliteit van de jeugdhulp",
					items: [
						{
							label: "Jeugdhulp dichterbij(t)huis",
							value: 0
						},
						{
							label: "Juiste zorg op het juiste moment(ook voor de complexe doelgroep)",
							value: 0
						}
					]
				}],
				step_by_step_plan: {
					plan: "",
				},
				results: {
					result: "",
				},
				informatics: {
					impact: "",
				},
				financial_perspective: {
					scenario: "",
				},
				terms: {
					terms: "",
				},

			},
		};
	},
	mounted() {
		if (this.$route.params.subid != "nieuw") {
			this.getTransformationCanvas();
		} else {
			this.edit = true;
		}
	},

	computed: {
		measurementTotal() {
			let total = 0;
			this.data.measurement.forEach(subject => {
				subject.items.forEach(item => {
					total = parseInt(total) + parseInt(item.value);
				});
			});
			return total;
		},
		measurementCompleteText() {
			if (this.measurementTotal > 100) {
				return `Let op: Er zijn ${(parseInt(this.measurementTotal - 100))} punten te veel verdeeld.`
			} else if (this.measurementTotal < 100) {
				return `Er zijn nog ${(100 - parseInt(this.measurementTotal))} punten te verdelen.`
			} else {
				return ""
			}
		},
		measurementCompleted() {
			return this.measurementTotal == 100;
		}

	},
	methods: {
		getTransformationCanvas() {
			api.get(`${config.url.api}/v1/transformationcanvas/${this.$route.params.subid}`).then((res) => {
				this.data = res.data.data.data;
				this.edit = res.data.data.userrole == "PU";
				console.log(res.data.data.userrole);
				this.ready = true;
			}).catch((err) => {
				switch (err.response.status) {
					case 401:
						this.$router.push("/pagina-niet-gevonden")
						break;
					case 404:
						this.$router.push("/pagina-niet-gevonden")
						break;
					default:
						this.$toast.error('Fout bij het ophalen van de transformatie canvas.')
						break;
				}
			})
		},
		download() {
			api.get(`${config.url.api}/v1/transformationcanvas/${this.$route.params.subid}/download`).then((res) => {
				var blob = new Blob([res.data], {
					type: 'application/pdf',
				});

                this.saveBlobAsPDF(blob, 'voorbeeld.pdf');

			}).catch((err) => {
				switch (err.response.status) {
					case 401:
						this.$toast.error('Je hebt geen toegang tot dit transformatie canvas.')
						break;
					default:
						this.$toast.error(`Er is iets fout gegaan (${err.response.status})`)
						break;
				}
			})
		},
		submit(type) {
			var data = {
				project: this.$route.params.id,
				data: this.data,
				type: type
			};
			var patchslug = this.$route.params.subid == "nieuw" ? 0 : this.$route.params.subid;
			api.patch(`${config.url.api}/v1/transformationcanvas/${patchslug}`, data).then((res) => {
				if (res.data.success) {
					//redirect to the newly created recource.
					switch (type) {
						case "concept":
							this.$toast.success('Concept Transformatie canvas succesvol geupdate.');
							break;
						case "save":
							this.$toast.success('Transformatie canvas succesvol ingeleverd.');
							this.nextdialog = false;
							break;
					}

					if (patchslug == 0) {
						this.$router.push("/project/" + this.$route.params.id + "/transformatie-canvas/" + res.data.slug)
					}

				} else {
					this.$toast.error('Er ging iets mis bij het opslaan van je transformatiecanvas.')
				}
			}).catch((err) => {
				switch (err.response.status) {
					case 401:
						this.$toast.error('Je hebt geen toegang tot dit transformatie canvas.')
						break;
					default:
						this.$toast.error(`Er is iets fout gegaan (${err.response.status})`)
						break;
				}
			})
		},
		saveBlobAsPDF(blob, bestandsnaam) {
			// Maak een link element aan
			var link = document.createElement('a');

			// Zet de blob als de gegevensbron voor de link
			link.href = window.URL.createObjectURL(blob);

			// Stel het downloadattribuut in met de opgegeven bestandsnaam
			link.download = bestandsnaam;

			// Voeg de link toe aan de DOM (Document Object Model)
			document.body.appendChild(link);

			// Simuleer een klik op de link om het downloaden te starten
			link.click();

			// Verwijder de link uit de DOM
			document.body.removeChild(link);
		},
		next() {
			this.tab++;
			window.scrollTo(0, 0);
		},
		prev() {
			this.tab--;
			window.scrollTo(0, 0);
		},
	},
};
</script>

<style>
.sidebar-drawer {
	height: 100%;
	position: relative;
}

.sidebar-drawer .bottom-text {
	position: absolute !important;
	bottom: 20px !important;
	font-size: 16px;
	color: #3f4a5e80;
}

.theme--light.v-application {
	background: rgba(255, 255, 255, 0.96) !important;
	border-radius: 10px !important;
	margin-left: 5px;
	margin-top: 5px;
}

.v-list-item__title {
	font-size: 20px !important;
}

.v-list-item small {
	font-size: 14px;
}

.v-list-item.v-item--active {
	font-weight: bold;
}

.number-input {
	width: 70px;
}
</style>
