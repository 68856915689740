<template>
  <div>

    <v-navigation-drawer v-model="sidebar" light floating clipped app width="350" height="100%"
      color="rgba(255,255,255,0.96)" class="mt-1 mr-1 pa-10 rounded-lg rounded-l-0">
      <v-list flat light>
        <v-list-item-group v-model="tab" light>
          <v-list-item color="themepurple" v-for="(item, i) in menuitems" :key="i" :disabled="i == tab">
            <v-list-item-title :key="i">
              {{ item.step }} {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <v-spacer></v-spacer>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar-nav-icon class="left-top-absolute ma-4" @click.stop="sidebar = !sidebar"></v-app-bar-nav-icon>
    <v-main class="pa-0 ml-1 px-15 pb-15">
      <div v-if="tab === 0">
        <h1 class="mb-5">Monitoringsplan</h1>
        <v-row>
          <v-col cols="12" md="6">

            <strong>Deze tool helpt je bij het maken van een meetplan om je project goed te monitoren. Voor het monitoren
              is het belangrijk dat je een heldere beschrijving hebt van wat je met het project* wilt bereiken.</strong>
            <br />
            <ul>
              <li>Voor welk probleem moet jouw project een oplossing bieden?</li>
              <li>Wat is dan de verandering die je tot stand wil brengen?</li>
              <li>Wie gaat deze verandering merken?</li>
              <li>Welke doelen wil je bereiken?</li>
              <li>Wanneer vind je dat het project succesvol is afgerond?</li>
            </ul>
            <br />
            Deze vragen worden beantwoord in het transformatiecanvas. Deze vragen moeten beantwoord zijn voor je kunt
            starten met het maken van dit
            monitoringsplan. Zijn ze helder? Dan kun je starten met deze tool. Niet? Ga dan eerst met elkaar kijken of je
            antwoord kunt vinden op de gestelde vragen.
            <br />
            <br />
            In dit plan is aandacht voor WAT je gaat meten. Welke doelen heb
            je?
            <br />
            Wat is de verandering, wie merkt het en hoe meet je het?<br />Dus waar kun je halverwege of na afloop van het
            project aan zien of je de resultaten ook hebt bereikt?<br />Daarvoor kijk je naar de indicatoren. De drie
            universele outcome indicatoren cliëntervaring, doelrealisatie en uitval zijn voor alle projecten gelijk.
            We helpen je ook met het kiezen van projectspecifieke indicatoren en de daarbij behorende meetinstrumenten.
            Daarnaast is er ook aandacht voor HOE je gaat meten? Wanneer? Hoe? Wie is ervoor verantwoordelijk? Waar ga je
            je meetgegevens bewaren? En hoe verwerk je ze?
            <br /><br />*Voor 'project' moet ook gelezen worden: programma, activiteit,
            beleidskader en interventie.

          </v-col>
          <v-col cols="12" md="1"></v-col>
          <v-col cols="12" md="5">
          </v-col>
        </v-row>
        <v-footer class="ml-2 pa-10 footer-buttons" color="transparent" fixed>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined @click="submit('concept')"
                v-if="edit" color="themepurple"><v-icon>fas fa-save</v-icon></v-btn>
              <v-btn class="mr-3 btn" outlined to="/" color="themepurple">Terug</v-btn>
              <v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
            </v-col>
          </v-row>
        </v-footer>
      </div>

      <div v-if="tab === 1">
        <v-row>
          <v-col cols="1" class="d-lg-block d-sm-none">
            <p class="number-label">{{ tab }}</p>
          </v-col>
          <v-col cols="12" md="11">
            <h1 class="mb-5">Over het project</h1>
            <v-row>

              <v-col cols="11" md="6">
                <v-row>
                  <v-col cols="12" md="3">
                    <label for="" class="mt-3 d-block">Projectnaam</label>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field solo class="theme-input" background-color="#ECF4EF" type="text" v-model="project.title"
                      readonly hide-details>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <label for="" class="mt-3 d-block">Projectaanvrager</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field solo class="theme-input" background-color="#ECF4EF" type="text"
                      v-model="project.owner.fullname" readonly hide-details>
                    </v-text-field>
                  </v-col>
                  <v-col cols="3"></v-col>
                  <v-col cols="12">
                    <label for="">Wat is de aanleiding van project?</label>
                    <v-textarea background-color="#ECF4EF" class="theme-input" solo title="Aanleiding"
                      v-model="data.about.reason" :readonly="!edit"></v-textarea>
                    <label for="">Wat is de gewenste verandering?</label>
                    <v-textarea background-color="#ECF4EF" class="theme-input" solo title="Gewenste verandering"
                      v-model="data.about.desired" :readonly="!edit"></v-textarea>
                  </v-col>
                  <v-col cols="12">
                  </v-col>

                </v-row>

              </v-col>
              <v-col cols="12" md="1"></v-col>
              <v-col cols="12" md="5">

              </v-col>
            </v-row>
            <v-footer class="ml-2 pa-10 footer-buttons" color="transparent" fixed>
              <v-row>
                <v-col cols="12" class="text-right">
                  <v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined v-if="edit"
                    @click="submit('concept')" color="themepurple"><v-icon>fas fa-save</v-icon></v-btn>
                  <v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
                  <v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
                </v-col>
              </v-row>
            </v-footer>
          </v-col>
        </v-row>
      </div>

      <div v-if="tab === 2">
        <v-row>
          <v-col cols="1" class="d-lg-block d-sm-none">
            <p class="number-label">{{ tab }}</p>
          </v-col>
          <v-col cols="12" md="11">

            <h1 class="mb-5">Jullie project langs de meetlaat</h1>
            <v-row>
              <v-col cols="12" md="6">
                <div v-for="(subject, x) in data.measurement" :key="x">
                  <p :class="x > 0 ? 'mt-10 mb-10' : 'mt-0 mb-10'">
                    <label for="">{{ subject.label }}</label>
                  </p>
                  <v-row :key="i" v-for="(item, i) in subject.items">
                    <v-col cols="2" class="py-1">
                      <v-text-field solo dense width="50px" class="theme-input number-input" background-color="#ECF4EF"
                        type="number" hide-details min="0" max="100" v-model="data.measurement[x].items[i].value"
                        :readonly="!edit">
                      </v-text-field>
                    </v-col>
                    <v-col cols="10" class="my-auto py-1">
                      <p class="mb-0">
                        {{ item.label }}
                      </p>
                    </v-col>
                  </v-row>
                </div>
                <div class="my-10"></div>
                <p>Noteer hier je drie hoogste scores, dit worden sowieso meetpunten in je project. Probeer alvast te
                  bedenken
                  of je dit kunt meten met een of meer van de drie standaard outcome indicatoren cliëntervaring,
                  doelrealisatie en uitval of dat je hiervoor een projectspecifieke indicator moet formuleren.</p>
                <div class="row">
                  <div class="col-1 d-inline-flex">
                    <p class="my-auto mx-auto d-block"><strong>1</strong></p>
                  </div>
                  <div class="col-11">
                    <v-text-field solo class="theme-input" background-color="#ECF4EF" type="text"
                      v-model="data.measurementscores[0].value" :readonly="!edit" hide-details></v-text-field>
                  </div>
                  <div class="col-1 d-inline-flex">
                    <p class="my-auto mx-auto d-block"><strong>2</strong></p>
                  </div>
                  <div class="col-11">
                    <v-text-field solo class="theme-input" background-color="#ECF4EF" type="text"
                      v-model="data.measurementscores[1].value" :readonly="!edit" hide-details></v-text-field>
                  </div>
                  <div class="col-1 d-inline-flex">
                    <p class="my-auto mx-auto d-block"><strong>3</strong></p>
                  </div>
                  <div class="col-11">
                    <v-text-field solo class="theme-input" background-color="#ECF4EF" type="text"
                      v-model="data.measurementscores[2].value" :readonly="!edit" hide-details></v-text-field>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="1"></v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-footer class="ml-2 pa-10 footer-buttons" color="transparent" fixed>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined v-if="edit"
                @click="submit('concept')" color="themepurple"><v-icon>fas fa-save</v-icon></v-btn>
              <v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
              <v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
            </v-col>
          </v-row>
        </v-footer>
      </div>

      <div v-if="tab === 3">
        <v-row>
          <v-col cols="1">
            <p class="number-label">{{ tab }}</p>
          </v-col>
          <v-col cols="12" md="11">
            <h1 class="mb-5">Projectresultaten</h1>
            <v-row>
              <v-col cols="6">
                <label for="">Resultaat 1</label>
                <v-text-field solo class="theme-input mb-5" background-color="#ECF4EF" type="text"
                  v-model="data.projectresults[0].value" :readonly="!edit" hide-details></v-text-field>
                <label for="">Resultaat 2</label>
                <v-text-field solo class="theme-input mb-5" background-color="#ECF4EF" type="text"
                  v-model="data.projectresults[1].value" :readonly="!edit" hide-details></v-text-field>
                <label for="">Resultaat 3</label>
                <v-text-field solo class="theme-input mb-5" background-color="#ECF4EF" type="text"
                  v-model="data.projectresults[2].value" :readonly="!edit" hide-details></v-text-field>
                <label for="">Resultaat 4</label>
                <v-text-field solo class="theme-input mb-5" background-color="#ECF4EF" type="text"
                  v-model="data.projectresults[3].value" :readonly="!edit" hide-details></v-text-field>
                <label for="">Resultaat 5</label>
                <v-text-field solo class="theme-input" background-color="#ECF4EF" type="text"
                  v-model="data.projectresults[4].value" :readonly="!edit" hide-details></v-text-field>
              </v-col>
            </v-row>

          </v-col>
        </v-row>
        <v-footer class="ml-2 pa-10 footer-buttons" color="transparent" fixed>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined v-if="edit"
                @click="submit('concept')" color="themepurple"><v-icon>fas fa-save</v-icon></v-btn>
              <v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
              <v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
            </v-col>
          </v-row>
        </v-footer>
      </div>

      <div v-if="tab === 4">
        <v-row>
          <v-col cols="1">
            <p class="number-label">{{ tab }}</p>
          </v-col>
          <v-col cols="12" md="11">
            <h1 class="mb-5">Het monitoren van je resultaten</h1>
            <v-row>
              <v-col cols="12" md="6">
                <label for="">
                  Bij deze tool hebben we drie bijlagen toegevoegd waarin je passende meetinstrumenten kunt vinden om de
                  standaard outcome indicatoren te meten.
                </label>
                <br /><br />

                <p>
                  Waaraan kun je de resultaten straks aflezen'? In stap 4 ga je bepalen hoe je resultaten gaat meten. Soms
                  zijn er kwantitatieve indicatoren. We noemen ze kwantitatief als het gaat om dingen die je kunt tellen.
                  Bij
                  wachttijden tel je bijvoorbeeld het aantal dagen of weken. Bij een groep tel je het aantal jongeren met
                  een
                  diploma of het aantal jongeren dat uit huis is geplaatst. Ook bijvoorbeeld crisismeldingen kun je in
                  aantal
                  tellen. Verschillende typen indicatoren zijn ook te vinden in het Kwaliteitskompas. Hieronder vind je
                  een
                  link naar het kompas.<br /><br />Andere uitkomsten kun je niet tellen. Denk bijvoorbeeld aan de
                  tevredenheid van de
                  deelnemers aan het project of de manier waarop de projectleden hebben samengewerkt. Die samenwerking
                  wordt
                  relevant als verschillende zorg-professionals samen een aanpak hebben vormgegeven. Je zult dan gebruik
                  moeten maken van kwalitatieve indicatoren. Om te zien of je doel bereikt is, moet je vragenlijsten of
                  interviews afnemen.<br /><br /> De drie grote outcome indicatoren moeten altijd gemeten worden. Dat zijn
                  doelrealisatie,
                  uitval en cliëntervaring. Door het meten van de grote outcome indicatoren krijg je een goed beeld van je
                  score op de Transformatiemeetlat. Hiermee kun je dus aantonen in hoeverre je project voldoet aan de
                  Transformatiemeetlat en hoe je bijdraagt aan de Transformatiedoelen.
                </p>
              </v-col>
              <v-col cols="12" md="1"></v-col>
              <v-col cols="12" md="5">
                <detailsshowbuttons />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-footer class="ml-2 pa-10 footer-buttons" color="transparent" fixed>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined v-if="edit"
                @click="submit('concept')" color="themepurple"><v-icon>fas fa-save</v-icon></v-btn>
              <v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
              <v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
            </v-col>
          </v-row>
        </v-footer>
      </div>

      <div v-if="tab === 5">
        <v-row>
          <v-col cols="1">
            <p class="number-label">4</p>
          </v-col>
          <v-col cols="12" md="11">
            <h1 class="mb-5">Resultaat 1</h1>
            <v-row>
              <v-col cols="12" md="6">
                <label for="">Wat is de gewenste verandering?</label>
                <v-textarea background-color="#ECF4EF" class="theme-input" solo title="Wat is de gewenste verandering?"
                  :readonly="!edit" v-model="data.results[0].desired"></v-textarea>
                <label for="">Mijn belangrijkste indicator(en)</label>
                <Indicators v-model="data.results[0].indicators" :edit="edit" />
              </v-col>
              <v-col cols="12" md="1"></v-col>
              <v-col cols="12" md="5">
                <detailsshowbuttons />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-footer class="ml-2 pa-10 footer-buttons" color="transparent" fixed>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined v-if="edit"
                @click="submit('concept')" color="themepurple"><v-icon>fas fa-save</v-icon></v-btn>
              <v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
              <v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
            </v-col>
          </v-row>
        </v-footer>
      </div>

      <div v-if="tab === 6">
        <v-row>
          <v-col cols="1">
            <p class="number-label">4</p>
          </v-col>
          <v-col cols="12" md="11">
            <h1 class="mb-5">Resultaat 2</h1>
            <v-row>
              <v-col cols="12" md="6">
                <label for="">Wat is de gewenste verandering?</label>
                <v-textarea background-color="#ECF4EF" class="theme-input" solo title="Wat is de gewenste verandering?"
                  :readonly="!edit" v-model="data.results[1].desired"></v-textarea>
                <label for="">Mijn belangrijkste indicator(en)</label>
                <Indicators v-model="data.results[1].indicators" :edit="edit" />
              </v-col>
              <v-col cols="12" md="1"></v-col>
              <v-col cols="12" md="5">
                <detailsshowbuttons />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-footer class="ml-2 pa-10 footer-buttons" color="transparent" fixed>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined v-if="edit"
                color="themepurple" @click="submit('concept')"><v-icon>fas fa-save</v-icon></v-btn>
              <v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
              <v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
            </v-col>
          </v-row>
        </v-footer>
      </div>

      <div v-if="tab === 7">
        <v-row>
          <v-col cols="1">
            <p class="number-label">4</p>
          </v-col>
          <v-col cols="12" md="11">
            <h1 class="mb-5">Resultaat 3</h1>
            <v-row>
              <v-col cols="12" md="6">
                <label for="">Wat is de gewenste verandering?</label>
                <v-textarea background-color="#ECF4EF" class="theme-input" solo title="Wat is de gewenste verandering?"
                  :readonly="!edit" v-model="data.results[2].desired"></v-textarea>
                <label for="">Mijn belangrijkste indicator(en)</label>
                <Indicators v-model="data.results[2].indicators" :edit="edit" />
              </v-col>
              <v-col cols="12" md="1"></v-col>
              <v-col cols="12" md="5">
                <detailsshowbuttons />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-footer class="ml-2 pa-10 footer-buttons" color="transparent" fixed>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined v-if="edit"
                color="themepurple" @click="submit('concept')"><v-icon>fas fa-save</v-icon></v-btn>
              <v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
              <v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
            </v-col>
          </v-row>
        </v-footer>
      </div>

      <div v-if="tab === 8">
        <v-row>
          <v-col cols="1">
            <p class="number-label">4</p>
          </v-col>
          <v-col cols="12" md="11">
            <h1 class="mb-5">Resultaat 4</h1>
            <v-row>
              <v-col cols="12" md="6">
                <label for="">Wat is de gewenste verandering?</label>
                <v-textarea background-color="#ECF4EF" class="theme-input" solo title="Wat is de gewenste verandering?"
                  :readonly="!edit" v-model="data.results[3].desired"></v-textarea>
                <label for="">Mijn belangrijkste indicator(en)</label>
                <Indicators v-model="data.results[3].indicators" :edit="edit" />
              </v-col>
              <v-col cols="12" md="1"></v-col>
              <v-col cols="12" md="5">
                <detailsshowbuttons />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-footer class="ml-2 pa-10 footer-buttons" color="transparent" fixed>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined v-if="edit"
                color="themepurple" @click="submit('concept')"><v-icon>fas fa-save</v-icon></v-btn>
              <v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
              <v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
            </v-col>
          </v-row>
        </v-footer>
      </div>

      <div v-if="tab === 9">
        <v-row>
          <v-col cols="1">
            <p class="number-label">5</p>
          </v-col>
          <v-col cols="12" md="11">
            <h1 class="mb-5">Vastleggen van je monitoringsgegevens</h1>
            <v-row>
              <v-col cols="12" md="6">
                <label for="">Wie is binnen het project verantwoordelijk voor de monitoringsgegevens?</label>
                <v-text-field solo class="theme-input mb-5" background-color="#ECF4EF" type="text"
                  v-model="data.capturing.responsible" :readonly="!edit" hide-details></v-text-field>
                <label for="">Heb je een monitoringswerkproces ingericht? Wie verzamelt welke gevens op welk moment en op
                  welke manier?</label>
                <v-textarea background-color="#ECF4EF" class="theme-input" solo
                  title="Heb je een monitoringswerkproces ingericht? Wie verzamelt welke gevens op welk moment en op welke manier?"
                  :readonly="!edit" v-model="data.capturing.process"></v-textarea>
                <label for="">Op welke manier heb je AVG geborgd? Is dit de manier waarop je gegevens hebt opgevraagd en
                  opgeslagen conform de AVG?</label>
                <v-text-field solo class="theme-input mb-5" background-color="#ECF4EF" type="text"
                  v-model="data.capturing.avg" :readonly="!edit" hide-details></v-text-field>
              </v-col>
              <v-col cols="12" md="1"></v-col>
              <v-col cols="12" md="5">
                <detailsshowbuttons />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-footer class="ml-2 pa-10 footer-buttons" color="transparent" fixed>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined v-if="edit"
                @click="submit('concept')" color="themepurple"><v-icon>fas fa-save</v-icon></v-btn>
              <v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
              <v-btn class="btn" @click="nextdialog = true" dark color="themepurple">Volgende</v-btn>
            </v-col>
          </v-row>
        </v-footer>
      </div>
      <!-- Download / submit dialog -->
      <v-dialog v-model="nextdialog" content-class="theme-popup">
        <div class="text-right">
          <v-btn @click="nextdialog = false" icon class="" elevation="0" color="themepurple">
            <v-icon>fas fa-times </v-icon>
          </v-btn>
        </div>
        <div v-if="(senddate = true)">
          <div class="text-center pb-5">
            <h3>Weet je zeker dat je wil inleveren?</h3>
          </div>
          <div class="text-center">
            <p>
              Wanneer je hem inlevert word de Procesmanager/Clustermanager/Lijnmanager op de hoogste
              gesteld.
            </p>
          </div>
          <div class="text-center pt-5">
            <v-btn class="btn text-primary mr-3" color="#fff" @click="download()">Downloaden</v-btn>
            <v-btn class="btn" color="themepurple" dark @click="submit('save')">Inleveren</v-btn>
          </div>
        </div>
        <div v-else>
          <div class="text-center pb-5">
            <h3>Verstuurd</h3>
          </div>
          <div class="text-center">
            <p>Document is verstuurd naar organisator.</p>
          </div>
        </div>
      </v-dialog>
    </v-main>
  </div>
</template>

<script>
// import optiontable from "@/components/optiontable.vue";
import detailsshowbuttons from '@/components/dialogs/project/detailsshowbuttons.vue';
import Indicators from '@/components/indicators.vue';
import { mapGetters } from 'vuex'
import api from "../../../services/api";
import config from '../../../config';
export default {

  components: { detailsshowbuttons, Indicators },
  data() {
    return {
      submittype: "concept",
      sidebar: true,
      ready: true,
      nextdialog: false,
      senddata: false,
      edit: false,
      menuitems: [
        {
          title: "Monitoringsplan",
          slug: "monitoringsplan",
          step: ""
        },
        {
          title: "Over het project",
          slug: "over",
          step: 1
        },
        {
          title: "Jullie project langs de meetlat",
          slug: "meetlat",
          step: 2
        },
        {
          title: "Projectresultaten",
          slug: "resultaten",
          step: 3
        },
        {
          title: "Het monitoren van je resultaten",
          slug: "monitoren",
          step: 4
        },
        {
          title: "Resultaat 1",
          slug: "resultaat-1",
          step: 4.1
        },
        {
          title: "Resultaat 2",
          slug: "resultaat-2",
          step: 4.2
        },
        {
          title: "Resultaat 3",
          slug: "resultaat-3",
          step: 4.3
        },
        {
          title: "Resultaat 4",
          slug: "resultaat-4",
          step: 4.4
        },
        {
          title: "Vastleggen van je monitoringsgegevens",
          slug: "monitoringsgegevens",
          step: 5
        },
      ],
      project: {
        id: -1,
        owner: {
          fullname: "Onbekend"
        },
        title: "Onbekend"
      },
      tab: 0,
      data: {
        about: {
          reason: "",
          desired: "",
        },
        measurement: [{
          label: "Perspectief van ouders en jeugdige versterken/ verbeteren",
          items: [
            {
              label: "Het belang van de jeugdige staat voorop",
              value: 0
            },
            {
              label: "Jeugdigen en / of ouders worden actief betrokken",
              value: 0
            },
            {
              label: "Versterking van eigen kracht en verantwoordelijkheid van jeugdige en / of ouders",
              value: 0
            }
          ]
        }, {
          label: "Perspectief van de professionals",
          items: [
            {
              label: "Betere samenwerking: 1 gezin, 1 plan, 1 regisseur",
              value: 0
            },
            {
              label: "Minder regeldruk voor professionals",
              value: 0
            }
          ]
        },
        {
          label: "Kostenbeheersing",
          items: [
            {
              label: "Meer doelmatige hulp(efficiënt / effectief)",
              value: 0
            },
            {
              label: "Geen onnodige jeugdhulp als ook het netwerk iets kan betekenen (normaliseren en ontzorgen)",
              value: 0
            }
          ]
        },
        {
          label: "Verbeteren van kwaliteit van de jeugdhulp",
          items: [
            {
              label: "Jeugdhulp dichterbij(t)huis",
              value: 0
            },
            {
              label: "Juiste zorg op het juiste moment(ook voor de complexe doelgroep)",
              value: 0
            }
          ]
        }],
        measurementscores: [
          { value: "" },
          { value: "" },
          { value: "" }
        ],
        projectresults: [
          { value: "" },
          { value: "" },
          { value: "" },
          { value: "" },
          { value: "" },
        ],
        results: [{
          desired: "",
          indicators: [
            {
              title: "",
              measurement: "",
              informant: ""
            }
          ]
        },
        {
          desired: "",
          indicators: [
            {
              title: "",
              measurement: "",
              informant: ""
            }
          ]
        }, {
          desired: "",
          indicators: [
            {
              title: "",
              measurement: "",
              informant: ""
            }
          ]
        }, {
          desired: "",
          indicators: [
            {
              title: "",
              measurement: "",
              informant: ""
            }
          ]
        }],
        capturing: {
          responsible: "",
          process: "",
          avg: ""
        }

      },
    };
  },
  mounted() {
    if (this.$route.params.subid != "nieuw") {
      this.getMonitoringPlan();
    } else {
      this.edit = true;
    }
    this.getProjectDetails();
  },

  computed: {
    ...mapGetters({ user: 'getuser' }),
    measurementTotal() {
      let total = 0;
      this.data.measurement.forEach(subject => {
        subject.items.forEach(item => {
          total = parseInt(total) + parseInt(item.value);
        });
      });
      return total;
    },
    measurementCompleteText() {
      if (this.measurementTotal > 100) {
        return `Let op: Er zijn ${(parseInt(this.measurementTotal - 100))} punten te veel verdeeld.`
      } else if (this.measurementTotal < 100) {
        return `Er zijn nog ${(100 - parseInt(this.measurementTotal))} punten te verdelen.`
      } else {
        return ""
      }
    },
    measurementCompleted() {
      return this.measurementTotal == 100;
    }

  },
  methods: {
    getProjectDetails() {
      api.get(`${config.url.api}/v1/projects/${this.$route.params.id}`).then((res) => {
        this.project.id = res.data.id;
        this.project.owner.fullname = res.data.owner.name.firstname + " " + res.data.owner.name.lastname;
        this.project.title = res.data.title;
      }).catch((err) => {
        switch (err.response.status) {
          case 401:
            this.$router.push("/pagina-niet-gevonden")
            break;
          case 404:
            this.$router.push("/pagina-niet-gevonden")
            break;
          default:
            this.$toast.error('Fout bij het ophalen van het Monitoringsplan.')
            break;
        }
      })
    },
    getMonitoringPlan() {
      api.get(`${config.url.api}/v1/monitoringplan/${this.$route.params.subid}`).then((res) => {
        this.data = res.data.data.data;
        this.edit = res.data.data.userrole == "PU" && this.user.role == "PU";

        this.ready = true;
      }).catch((err) => {
        switch (err.response.status) {
          case 401:
            this.$router.push("/pagina-niet-gevonden")
            break;
          case 404:
            this.$router.push("/pagina-niet-gevonden")
            break;
          default:
            this.$toast.error('Fout bij het ophalen van het Monitoringsplan.')
            break;
        }
      })
    },
    submit(type) {
      var data = {
        project: this.$route.params.id,
        data: this.data,
        type: type
      };
      var patchslug = this.$route.params.subid == "nieuw" ? 0 : this.$route.params.subid;
      api.patch(`${config.url.api}/v1/monitoringplan/${patchslug}`, data).then((res) => {
        if (res.data.success) {
          //redirect to the newly created recource.
          switch (type) {
            case "concept":
              this.$toast.success('Concept Monitoringsplan succesvol geupdate.');
              break;
            case "save":
              this.$toast.success('Monitoringsplan succesvol ingeleverd.');
              this.nextdialog = false;
              break;
          }

          if (patchslug == 0) {
            this.$router.push("/project/" + this.$route.params.id + "/monitoringsplan/" + res.data.slug)
          }

        } else {
          this.$toast.error('Er ging iets mis bij het opslaan van je monitoringsplan.')
        }
      }).catch((err) => {
        switch (err.response.status) {
          case 401:
            this.$toast.error('Je hebt geen toegang tot dit monitoringsplan.')
            break;
          default:
            this.$toast.error(`Er is iets fout gegaan (${err.response.status})`)
            break;
        }
      })
    },
    next() {
      this.tab++;
      window.scrollTo(0, 0);
    },
    prev() {
      this.tab--;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style >
body {
  transition: all ease-in-out 0.2s;
  background-color: #e8f4f6;
}

.number-label {
  background: #ECF4EF 0% 0% no-repeat padding-box;
  margin: auto;
  display: block;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  text-align: center;
  font-weight: 900;
  font-size: 20px;
  padding-top: 5px;
}

.sidebar-drawer {
  height: 100%;
  position: relative;
}

.sidebar-drawer .bottom-text {
  position: absolute !important;
  bottom: 20px !important;
  font-size: 16px;
  color: #3f4a5e80;
}

.theme--light.v-application {
  background: rgba(255, 255, 255, 0.96) !important;
  border-radius: 10px !important;
  margin-left: 5px;
  margin-top: 5px;
}

.v-list-item__title {
  font-size: 20px !important;
}

.v-list-item small {
  font-size: 14px;
}

.v-list-item.v-item--active {
  font-weight: bold;
}

.number-input {
  width: 70px;
}

.details-show-btn {
  background: #f1f3f7;
  border: 1px solid #afafc3;
  border-radius: 16px;
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
  max-width: 475px;
  margin-bottom: 16px;

}

.details-show-btn.red-details i,
.details-show-btn.red-details button {
  color: #D37287;
}

.details-show-btn.yellow-details i,
.details-show-btn.yellow-details button {
  color: #D89A3E;
}

.details-show-btn-body {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 10px;


}

.details-show-btn-body p {
  margin-bottom: 0px
}

.details-show-btn button {
  text-transform: none !important;
  letter-spacing: 0px;
  padding: 25px 32px !important;
  background: #fff !important;
  border-radius: 0px;
}</style>
