<template>
  <div>
    <Projectsidebarmenu :sidebar="sidebar" />
    <v-app-bar-nav-icon class="left-top-absolute ma-4" @click.stop="sidebar = !sidebar"></v-app-bar-nav-icon>
    <v-main class="ml-1 pt-0 pa-15">
      <v-row>
        <v-col cols="12" md="">
          <h1 class="mb-5">Monitoringsplan</h1>
          <p>
            Monitoringsplan tekst
          </p>

        </v-col>

        <v-col cols="12">
          <v-btn @click="startcountingdialog = true" class="text-white btn" color="themepurple" :to="link"
            :disabled="!hasaccess" v-if="userrole == 'PU'">Monitoringsplan Starten</v-btn>
          <v-btn @click="startcountingdialog = true" class="text-white btn" color="themepurple" :to="link"
            :disabled="!hasaccess" v-else>Monitoringsplan Bekijken</v-btn>
        </v-col>
      </v-row>
      <v-footer class="ml-2 pa-10" color="transparent" fixed>
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn class="mr-3 btn" outlined to="/" color="themepurple">Terug</v-btn>
            <v-btn class="btn" disabled @click="next()" dark color="themepurple">Volgende</v-btn>
          </v-col>
        </v-row>
      </v-footer>
    </v-main>
  </div>
</template>

<script>
import Projectsidebarmenu from '@/components/projectsidebarmenu.vue';
import api from "../../../services/api";
import config from '../../../config';
import { mapGetters } from 'vuex'
export default {
  components: { Projectsidebarmenu },
  data() {
    return {
      sidebar: true,
      ready: true,
      nextdialog: false,
      startcountingdialog: false,
      senddata: false,
      tab: 0,
      link: "",
      hasaccess: false,
      userrole: ""
    };
  },
  computed: {
    ...mapGetters({ user: 'getuser' }),
  },
  mounted() {
    this.link = `/project/${this.$route.params.id}/monitoringsplan/nieuw`;
    this.getProjectMonitoringPlan();
  },
  methods: {
    getProjectMonitoringPlan() {
      api.get(`${config.url.api}/v1/monitoringplan/${this.$route.params.id}`).then((res) => {
        this.userrole = res.data.data.userrole;
        if (res.data.success) {
          this.hasaccess = true;
          this.link = `/project/${this.$route.params.id}/monitoringsplan/${res.data.data.slug}`;
        } else {
          this.userrole = res.data.role;
          //no existing transformatiecanvas found.
          if (this.userrole != "PM" && this.userrole != "OG") {
            this.hasaccess = true;
          }
        }
      })
    },
    next() {
      this.tab++;
      window.scrollTo(0, 0);
    },
    prev() {
      this.tab--;
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style >
body {
  transition: all ease-in-out 0.2s;
  background-color: #e8f4f6;
}
</style>